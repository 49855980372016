import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Table, Alert, Spinner, Modal, Col, Form, Button, Badge } from 'react-bootstrap';
import { fetchWeerepo, postWeerepoComment } from '../../../../firebase-config';
import Roles from '../../../../roles';

/**
 * ウィーレポアーカイブ画面.
 * 
 * @param {employee} 従業員 
 * @returns 
 */
function WeeRepoArchivePage() {
    const { state } = useLocation();
    const [list, setList] = useState([]);
    const { myEmployee, employee } = state;

    const [showError, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showGet, setShowGet] = useState(false);
    const [selectedArchive, setSelectedArchive] = useState(null);
    const [comment, setComment] = useState('');
    const [commentLoading, setCommentLoading] = useState(false);
    const [commentError, setCommentError] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);

    const handleGetClose = () => setShowGet(false);

    const handleGetShow = (archive) => {
        console.log(archive);
        setSelectedArchive({
            id: archive.id,
            ...archive.data
        });
        setShowGet(true);
    };

    const isAdmin = (roleId) => {
        for (const role of Object.values(Roles)) {
            if (role.id === roleId) {
                return role == Roles.ADMIN;
            }
        }
        return false;
    };

    /**
     * ウィーレポ一覧を取得.
     */
    const getWeeRepoList = async () => {
        setLoading(true);

        try {
            const list = await fetchWeerepo(employee.id, 20, 0);

            if (list) {
                setList(list);
                console.log(list);
                setError(false);
            } else {
                setError(true);
            }
        } catch (error) {
            console.error("Error fetching previous data:", error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (seconds) => {
        // UNIXタイムスタンプをDateオブジェクトに変換
        const date = new Date(seconds * 1000);

        // 日本時間にフォーマット
        const formatter = new Intl.DateTimeFormat('ja-JP', {
            year: 'numeric',  // 年を数値で表示
            month: 'long',    // 月を長い形式で表示
            day: 'numeric',   // 日を数値で表示
            weekday: 'long',  // 曜日を長い形式で表示
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'Asia/Tokyo' // タイムゾーンを東京に設定
        });

        return formatter.format(date);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    /**
     * ウィーレポに対してコメント送信.
     * 
     * @param {Error} e 
     * @returns 
     */
    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!comment.trim()) {
            return;
        }
        setCommentLoading(true);
        setCommentError(false);
        setCommentSuccess(false);

        try {
            await postWeerepoComment(selectedArchive.id, myEmployee.id, comment);
            setCommentSuccess(true);
            setComment('');
            window.location.reload();
        } catch (error) {
            console.error("Error posting comment:", error);
            setCommentError(true);
        } finally {
            setCommentLoading(false);
        }
    };

    /**
     * 改行コードをdiv区切りで変換.
     * 
     * @param {string} str 
     * @returns 改行された文字列
     */
    function convertBr(str) {
        if (!str) return null;
        return str.split("\n").map((line, index) => {
            return line === "" ? <div key={index}>&nbsp;</div> : <div key={index}>{line}</div>;
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "ウィーレポ アーカイブ - " + employee.lastName + ' ' + employee.firstName;
        getWeeRepoList();
    }, [employee]);

    return (
        <Row>
            <h1>ウィーレポ アーカイブ - {employee.lastName} {employee.firstName}</h1>

            <Table bordered className='mt-5'>
                <tbody>
                    <tr>
                        <th className='td-header' width={40}>No</th>
                        <th className='td-header' width={160}>投稿日時</th>
                        <th className='td-header' width={90}>業務量</th>
                        <th className='td-header' width={90}>メンタル</th>
                    </tr>
                </tbody>

                <tbody>
                    {list.length > 0 && (
                        list
                            .filter((archive) => archive.data != null)
                            .map((archive, index) => (
                                <tr key={archive.id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleGetShow(archive)}
                                >
                                    <td>{index + 1}</td>
                                    <td>
                                        {formatDate(archive.data.registerTime._seconds)}
                                        {archive.data.comment && (
                                            <>
                                                <br />
                                                <Badge bg="info">コメントあり</Badge>
                                            </>
                                        )}
                                    </td>
                                    <td>{archive.data.workEmoji.label} {archive.data.workEmoji.state}</td>
                                    <td>{archive.data.mentalEmoji.label} {archive.data.mentalEmoji.state}</td>
                                </tr>
                            ))
                    )}
                </tbody>
            </Table>

            {loading &&
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" variant="dark" />
                </div>
            }

            <Modal
                show={showGet}
                size="lg"
                onHide={handleGetClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{selectedArchive?.registerTime ? formatDate(selectedArchive.registerTime._seconds) : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={6}>
                            <p><strong>業務量</strong></p>
                            <p>{selectedArchive?.workEmoji?.label} {selectedArchive?.workEmoji?.state}</p>
                        </Col>
                        <Col xs={6}>
                            <p><strong>メンタル</strong></p>
                            <p>{selectedArchive?.mentalEmoji?.label} {selectedArchive?.mentalEmoji?.state}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <p><strong>現場</strong></p>
                            <ul>
                                {selectedArchive?.works?.map((str, index) => (
                                    <li key={index}>{str}</li>
                                ))}
                            </ul>
                        </Col>
                        <Col xs={6}>
                            <p><strong>自社</strong></p>
                            <ul>
                                {selectedArchive?.insideWorks?.map((str, index) => (
                                    <li key={index}>{str}</li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                    <p><strong>連絡事項</strong></p>
                    <p>{selectedArchive ? convertBr(selectedArchive.content) : ''}</p>
                    {selectedArchive?.comment ? (
                        <>
                            <p><strong>コメント</strong></p>
                            <p>{convertBr(selectedArchive.comment.content)}</p>
                        </>
                    ) : (
                        <Form onSubmit={handleCommentSubmit}>
                            {isAdmin(myEmployee.role) && (
                                <>
                                    <Form.Group controlId="formComment">
                                        <Form.Control
                                            as="textarea"
                                            rows={5}
                                            value={comment}
                                            onChange={handleCommentChange}
                                            placeholder="コメントを入力"
                                            required
                                        />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className='mt-2' disabled={commentLoading}>
                                        {commentLoading ? '送信中...' : '送信'}
                                    </Button>
                                </>
                            )}
                        </Form>
                    )}
                    {commentSuccess && <Alert variant="success" className="mt-3">コメントを送信しました。</Alert>}
                    {commentError && <Alert variant="danger" className="mt-3">コメントの送信に失敗しました。</Alert>}
                </Modal.Body>
            </Modal>

            {showError && <Alert variant="danger">取得失敗しました。</Alert>}
        </Row>
    );
}

export default WeeRepoArchivePage;
